@font-face { font-family: 'Muli'; src: url('/assets/fonts/Muli-Black.ttf'); font-weight: 900; }
@font-face { font-family: 'Muli'; src: url('/assets/fonts/Muli-Bold.ttf'); font-weight: 700; }
@font-face { font-family: 'Muli'; src: url('/assets/fonts/Muli-Regular.ttf'); font-weight: 500; }
@font-face { font-family: 'Muli'; src: url('/assets/fonts/Muli-Regular.ttf'); font-weight: 400; }
@font-face { font-family: 'Muli'; src: url('/assets/fonts/Muli-Light.ttf'); font-weight: 300; }
@font-face { font-family: 'Muli'; src: url('/assets/fonts/Muli-ExtraLight.ttf'); font-weight: 100; }


html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  background-color: rgba(25, 41, 58, 1);
}

html, body, .MuiTypography-root, th, tr, caption, input, textarea {
    font-family: 'Muli' !important;
}

.MuiTypography-h4 {
    font-weight: 700 !important;
}

.MuiTypography-body1 {
    font-weight: 500 !important;
    font-size: 1.1rem !important;
}

.MuiTypography-body2 {
    font-weight: 500 !important;
    line-height: 1.8 !important;
    letter-spacing: 0.03em !important; 
}

.block {
  display:block;
}

.w-full {
  width: 100%;
}

.clearfix::after {
  content: "";
  clear: both;
  display: table;
}

.cursor:hover {
  cursor: pointer;
}

/* SCROLL BAR */

#root ::-webkit-scrollbar {
  width: 0.8em;
  height: 0.8em;
}

#root ::-webkit-scrollbar-track {
  background-color: rgb(43, 58, 82);
}

#root ::-webkit-scrollbar-thumb {
  background-color: rgba(35, 48, 68, 1);
}

/* MARGINS */

.m-auto {
  margin: auto !important;
}

.m-0 {
  margin: 0 !important;
}

.m-05 {
  margin: 5px !important;
}

.m-1 {
  margin: 10px !important;
}

.m-2 {
  margin: 20px !important;
}

.ml-0 {
  margin-left: 0 !important;
}

.ml-05 {
  margin-left: 5px !important;
}

.ml-1 {
  margin-left: 10px !important;
}

.ml-2 {
  margin-left: 20px !important;
}

.mr-0 {
  margin-right: 0 !important;
}

.mr-05 {
  margin-right: 5px !important;
}

.mr-1 {
  margin-right: 10px !important;
}

.mr-2 {
  margin-right: 20px !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-05 {
  margin-top: 5px !important;
}

.mt-1 {
  margin-top: 10px !important;
}

.mt-2 {
  margin-top: 20px !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-05 {
  margin-bottom: 5px !important;
}

.mb-1 {
  margin-bottom: 10px !important;
}

.mb-2 {
  margin-bottom: 20px !important;
}

.ms-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.ms-1 {
  margin-left: 10px !important;
  margin-right: 10px !important;
}

.ms-2 {
  margin-left: 20px !important;
  margin-right: 20px !important;
}

/* PADDING */

.p-0 {
  padding: 0 !important;
}

.p-05 {
  padding: 5px !important;
}

.p-1 {
  padding: 10px !important;
}

.p-2 {
  padding: 20px !important;
}

.pl-0 {
  padding-left: 0 !important;
}

.pl-1 {
  padding-left: 10px !important;
}

.pl-2 {
  padding-left: 20px !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.pr-1 {
  padding-right: 10px !important;
}

.pr-2 {
  padding-right: 20px !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 10px !important;
}

.pt-2 {
  padding-top: 20px !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 10px !important;
}

.pb-2 {
  padding-bottom: 20px !important;
}

.ps-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.ps-1 {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.ps-2 {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}

a {
    text-decoration: none;
}

.autoSticky {
  position: -webkit-sticky;
  position: sticky;
  top: 70px;
}

/* COLORS */

.colorWhite {
  color: rgba(250, 250, 250, 1);
}

.colorError {
  color: rgba(223, 57, 57, 1);
}

.colorWarning, .colorWarning input, .colorWarning label {
  color: rgba(255, 160, 0, 1);
}

.colorSecondary {
  color: rgba(169, 190, 212, 1);
}

.colorLight, a {
  color: rgba(97, 222, 164, 1);
}


/* FONTS */

.w-bolder {
  font-weight: 900 !important;
}

.w-bold {
  font-weight: 700 !important;
}

.w-medium {
  font-weight: 500 !important;
}

.w-light {
  font-weight: 300 !important;
}


/* APP */

.MuiInput-underline:before {
  border-bottom: 1px solid rgba(169, 190, 212, 0.42) !important;
}

.graphiQlRoot ::-webkit-scrollbar {
  width: 0.8em;
  height: 0.8em;
}

.graphiQlRoot ::-webkit-scrollbar-track {
  background-color:  rgb(230, 230, 230) !important;
}

.graphiQlRoot ::-webkit-scrollbar-thumb {
  background-color: rgb(202, 202, 202) !important;
}

.graphiQlRoot {
  height: 700px;
}

.graphiql-container .doc-explorer-title-bar, .graphiql-container .history-title-bar {
    height: 60px !important;
}

.MuiDivider-root {
    background-color: rgba(255, 255, 2555, 0.12) !important;
}

.errorsMarkdown p {
  margin: 0 !important;
}

.fullscreenGqlButton {
  top: 20px;
}

.tooltip {
  font-size: 12px !important;
  font-weight: 500 !important;
  background-color: rgba(12, 21, 33, 1) !important;
  color: rgba(97, 222, 164, 1) !important;
}

.stepLabel span {
  color:rgba(169, 190, 212, 1) !important;
  font-weight: 700 !important;
}

.createVideoGuideSection {
  padding-top: 40px !important;
  padding-bottom: 40px !important;
}

.graphiql-explorer-root>div:first-of-type {
  padding: 0 5px !important;
}

.apiVideo, .creatorsVideo {
  width: 100%;
  margin:auto;
  display:block;
}

.apiVideo {
  max-width: 500px;
}

.creatorsVideo {
  max-width: 300px;
}

.introductionSection {
  padding: 100px 5% 0 5% !important;
}

.vertical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.creatorsList li {
  padding: 10px 0;
}

.creatorsSmallImg {
  width: 100%;
  max-width: 400px;
  margin: auto;
  display: block;
}

.creatorsMediumImg {
  width: 100%;
  max-width: 700px;
  margin: auto;
  display: block;
}

.ytCreatorChapter {
  display: inline-block;
  margin: 10px;
}

.ytCreatorBox {
  overflow-y: auto;
  width: 100%;
}

.ytCreatorContainer {
  max-width: 500px;
  margin:auto;
}

.ytCreatorPlayer {
  position: relative;
	padding-bottom: 56.25%;
	height: 0;
	overflow: hidden;
}

.ytCreatorPlayer iframe {
  position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.ytChaptersTabs .Mui-selected {
  color: rgba(97, 222, 164, 1) !important;
}

.createTemplateStepImg {
  margin: 10px auto;
  width: 100%;
  max-width: 1200px;
  display: block;
}

.uploadTemplateStepImg {
  margin: 10px auto;
  display: block;
  max-width: 100%;
}